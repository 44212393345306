import request from '@/utils/requestV2'
const qs = require('qs')

// 获取采购档期起始日的站点列表 含资源类型数量
export function getStationResourceTypePage (data) {
  return request({
    url: '/ooh-order/v1/orderstatistic/getstationresourcetypepage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取采购档期起始日的站点和产品数量统计
export function getStationProductQuantity (data) {
  return request({
    url: '/ooh-order/v1/orderstatistic/getstationproductquantity',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取订单内某资源的档期分布
export function getResourceScheduleList (data) {
  return request({
    url: '/ooh-order/v1/orderstatistic/getresourceschedulelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
