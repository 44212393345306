<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >

      <i-col
        span="24"
        class="text-right"
      >
        <Button
          type="success"
          size="small"
          class="m-r-5"
          @click="handleLanchUninstall"
        >提交下刊申请</Button>
      </i-col>
    </Row>
    <div class="m-b-10">
      <RadioGroup
        type="button"
        v-model="query.productId"
        @on-change="handleSearch"
      >
        <Radio
          :label="item.productId"
          v-for="item in orderProductArray"
          :key="item.productId"
        >{{item.productName}}</Radio>
      </RadioGroup>
    </div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.assetId"
          size="small"
          :clearable="true"
          placeholder="选择资产"
          @on-change="assetChange"
        >
          <Option
            v-for="item in assetArray"
            :key="'asset_'+item.assetId"
            :value="item.assetId"
          >{{item.assetName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.stationId"
          size="small"
          :clearable="true"
          placeholder="选择站点"
          @on-change="stataionChange"
        >
          <Option
            v-for="item in stationArray"
            :key="'asset_'+item.stationId"
            :value="item.stationId"
          >{{item.stationName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.position"
          size="small"
          :clearable="true"
          placeholder="选择位置"
        >
          <Option
            v-for="item in positionArray"
            :key="'asset_'+item.position"
            :value="item.position"
          >{{item.positionName}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Input
          v-model="query.keyword"
          size="small"
          placeholder="资源编号"
        />
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch"
        >搜索</Button>
      </i-col>
      <i-col span="6">
        <Button
          class="m-r-5"
          size="small"
          type="primary"
          :disabled="selectedResourceIds.length===0"
          @click="handleSetUninstall"
        >设置内容</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        @on-selection-change="selectionChange"
      ></Table>
      <div class="paging_style">
        <Page
          size="small"
          :total="tableData.totalRow"
          :page-size="query.pageSize"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          @on-change="changePage"
          :current="query.pageNumber"
        ></Page>
      </div>
    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box
        ref="imgLightBox"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
    </Modal>
    <Modal
      v-model="scheduleModal"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >当前资源档期分布</p>
      <div>
        <Table
          stripe
          size="small"
          :data="scheduleArray"
          :columns="scheduleColumns"
        ></Table>
      </div>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { getResourceParameter } from '@/api/order/productdetail'
import { getResourceScheduleList } from '@/api/order/orderstatistic'
import { submitUninstallApply } from '@/api/msp/demandV3'
import ImgLightBox from '@/components/common/ImgLightBox'
export default {
  mixins: [sysMixins],
  components: {
    ImgLightBox
  },
  props: {
    serviceType: { // 服务对象类型，1：销售，2：代理商
      type: Number,
      default: 1
    },
    extraColumns: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      assetArray: [],
      stationArray: [],
      positionArray: [],
      query: {
        taskId: this.$store.state.installSetting.currentTaskId,
        productId: null,
        assetId: '',
        stationId: null,
        position: null,
        resourcetypeId: null,
        keyword: '',
        pageSize: 15,
        pageNumber: 1
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '资源编号', key: 'resourceCode', width: 100, fixed: 'left' },
        { title: '所属站台', key: 'stationName', width: 100, fixed: 'left' },
        { title: '位置', key: 'positionName', align: 'center', minWidth: 100 },
        { title: '画面数', key: 'side', align: 'center', minWidth: 100 },
        {
          title: '在刊画面',
          minWidth: 100,
          align: 'center',
          key: 'currentScrren',
          render: (h, params) => {
            if (params.row.currentFileList && params.row.currentFileList.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.currentFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.currentFileList.map(x => x.fileKey)
                        }
                        this.fileName = params.row.stationName
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px',
                      cursor: 'pointer'
                    }
                  }
                )
              ])
            } else {
              return h('span', '')
            }
          }
        },
        // {
        //   title: '上刊名称',
        //   minWidth: 100,
        //   key: 'screenName',
        //   render: (h, params) => {
        //     return h('span', params.row.screenName)
        //   }
        // },
        { title: '备注', minWidth: 100, key: 'remark', ellipsis: true, tooltip: true },
        { title: '在刊结束日', minWidth: 100, align: 'center', key: 'pubEndDate' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.currentResId = params.row.resourceId
                  this.scheduleModal = true
                  this.handelViewSchedule()
                }
              }
            }, '档期分布')
          }
        }
      ],
      visiblePreview: false,
      fileName: '',
      fileUrl: '',
      sortIndex: 0,
      currentResId: null,
      scheduleModal: false,
      scheduleArray: [],
      scheduleColumns: [
        { title: '档期开始日期', align: 'center', key: 'startDate' },
        { title: '档期结束日期', align: 'center', key: 'endDate' }
      ]
    }
  },
  computed: {
    tableData () {
      return this.$store.state.installSetting.tableData
    },
    orderId () {
      return this.$store.state.installSetting.demandOrderId
    },
    selectedResourceIds: {
      get () {
        return this.$store.state.installSetting.selectedResourceIds
      },
      set (val) {
        this.$store.commit('set_selected_resourceIds', val)
      }
    },
    orderProductArray () {
      return this.$store.state.installSetting.orderProductArray
    },
    selectedProductId: {
      get () {
        return this.$store.state.installSetting.selectedProductId
      },
      set (val) {
        this.$store.commit('set_selected_productId', val)
      }
    },
    demandStartDate () {
      return this.$store.state.installSetting.demandStartDate
    },
    demandEndDate () {
      return this.$store.state.installSetting.demandEndDate
    }
    // selectedTaskIds: {
    //   get () {
    //     return this.$store.state.installSetting.selectedTaskIds
    //   },
    //   set (val) {
    //     this.$store.commit('set_selected_taskIds', val)
    //   }
    // }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray({ key: 'uninstallSetScreen', value: '下刊设置' })
    this.setActivedTagName('uninstallSetScreen')
  },
  methods: {
    /**
     * 获取资产数据
     */
    getAssetArray () {
      const query = {
        orderId: this.orderId,
        productId: this.query.productId,
        startDate: this.demandStartDate,
        endDate: this.demandEndDate
      }
      getResourceParameter(query).then(res => {
        if (res && !res.errcode) {
          this.assetArray = res.assetList
        } else {
          this.assetArray = []
        }
      })
    },
    assetChange () {
      this.query.stationId = ''
      this.query.position = ''
      if (this.query.assetId) {
        const asset = this.assetArray.find(x => x.assetId === this.query.assetId)
        this.stationArray = asset ? asset.stationList : []
      } else {
        this.stationArray = []
      }
    },
    stataionChange () {
      this.query.position = ''
      if (this.query.stationId) {
        const station = this.stationArray.find(x => x.stationId === this.query.stationId)
        this.positionArray = station ? station.positionList : []
      } else {
        this.positionArray = []
      }
    },
    /**
     * 获取table数据
     */
    getResouceData () {
      this.selectedTaskIds = []
      this.$store.dispatch('getPublishingTaskitemData', this.query)
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getResouceData(this.query)
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.getResouceData(this.query)
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getResouceData(this.query)
    },
    selectionChange (selections) {
      this.selectedResourceIds = selections.map(x => x.resourceId)
    },
    /**
     * 发起下刊申请
     */
    handleLanchUninstall () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交下刊申请？',
        onOk: () => {
          submitUninstallApply({ taskId: this.query.taskId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })

              // 切换到上刊设置列表界面
              this.setShowLeftBlock(true)
              this.setLeftComponent('MaterialList')
              this.setShowRightBlock(true)
              this.setRightComponent('InstallSummary')
              this.setBottomComponent('InstallDetail')
            }
          })
        }
      })
    },
    /**
     * 查看当前资源档期分布
     */
    handelViewSchedule () {
      getResourceScheduleList({ orderId: this.orderId, resourceId: this.currentResId }).then(res => {
        if (res && !res.errcode) {
          this.scheduleArray = res
        } else {
          this.scheduleArray = []
        }
      })
    },
    handleSetUninstall () {
      this.setLeftComponent('SetUninstall')
      this.setShowLeftBlock(true)
    },
    /**
     * 切换到上刊设置列表界面
     */
    handleGotoPage () {
      this.setShowLeftBlock(true)
      this.setLeftComponent('MaterialList')
      this.setShowRightBlock(true)
      this.setRightComponent('InstallSummary')
      this.setBottomComponent('InstallDetail')
    }
  },
  destroyed () {
    // 组件注销时，清空当前组件产生非公用数据
    this.$store.commit('set_table_data', {})
    this.$store.commit('set_selected_taskIds', [])
    this.selectedResourceIds = []
    this.selectedProductId = null
  },
  watch: {
    selectedProductId (val) {
      if (val) {
        this.query.productId = val
        this.getAssetArray()
        this.getResouceData()
      }
    }
    // orderProductArray: {
    //   deep: true,
    //   immediate: true,
    //   handler (val) {
    //     if (val && val.length > 0) {
    //       this.query.productId = val[0].productId
    //       this.getResouceData()
    //     }
    //   }
    // }
  }
}
</script>
